
    import { defineComponent, ref, watch, PropType, reactive, toRefs, onMounted } from 'vue';
    import { getWording } from "@/API/approve";

    export default defineComponent({
        name: "ProgramPreview",
        props: {
            visible: {
                type: Boolean
            },
            targetName: {
                type: String
            },
            program: {
                type: Object as PropType<{ programId: string; programPeriod: string }>,
                required: true
            }

        },
        emits: ['update:visible', 'update:program'],
        setup(props, content) {
            const programPreviewRef = ref();

            const wordingSrc = ref('');

            const scale = ref<number>(1);

            const imgPosition = reactive<{ width: number; height: number }>({ width: 0, height: 0 });

            const handlerOnZoom = (): void => {
                scale.value += 0.2;
            }

            const handlerInZoom = (): void => {
                if (scale.value > 1) {
                    scale.value -= 0.2;
                }
            }

            const handlerDocumentClick = (e: Event): void => {
                // if (wordingSrc.value && !programPreviewRef.value.contains(e.target)) {
                //     content.emit('update:visible', false);
                // }
            }

            watch(() => props.visible, (value) => {
                if (!value) {
                    scale.value = 1;
                    imgPosition.width = 0;
                    imgPosition.height = 0;
                    wordingSrc.value = ''
                    const program = Object.assign(props.program, { programId: '', programPeriod: '' })
                    content.emit('update:program', program);
                }
            })

            watch(props.program, (value) => {
                if (value.programId) {
                    // getWording(props.program.programId, props.program.programPeriod).then(data => {
                    //     const img = new Image();
                    //     const url = window.URL.createObjectURL(data);
                    //     img.onload = () => {
                    //         imgPosition.width = img.width;
                    //         imgPosition.height = img.height;
                    //         scale.value = 1;
                    //         window.URL.revokeObjectURL(img.src);
                    //     }
                    //     img.src = url;
                    //     wordingSrc.value = url;
                    // })
                }
            })

            onMounted(() => {
                document.addEventListener('click', handlerDocumentClick);
            })

            return {
                wordingSrc,
                scale,
                ...toRefs(imgPosition),

                handlerOnZoom,
                handlerInZoom,

                programPreviewRef
            }
        }
    })
