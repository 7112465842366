import { ref, watch, Ref } from 'vue';

function useTableRowHighlight(visible: Ref) {
    //当前点击的行
    const currentClickRowIndex = ref<number | null>();

    const visibleWording = ref<Ref>(visible);

    const handlerRowClick = (index: number | null): void => {
        currentClickRowIndex.value = index;
    }

    watch(visibleWording, (value => {
        !value && (currentClickRowIndex.value = null);
    }))

    return {
        currentClickRowIndex,
        handlerRowClick
    }
}

export default useTableRowHighlight;
